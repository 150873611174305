import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home/HomeView.vue"),
  },
  {
    path: "/work",
    name: "work",
    component: () => import("../views/work/WorkView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...routes,
    {
      path: "/:pathMatch(.*)",
      component: () => import("../views/notfound/NotFoundView.vue"),
    },
  ],
});

export default router;
