<template>
  <div>
    <canvas
      id="particles"
      class="fixed top-0 left-0 w-full h-full z-0"
    ></canvas>
    <main class="relative z-10">
      <router-view />
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import Particles from "@/classes/Particles";

export default defineComponent({
  setup() {
    let particles: Particles | null = null;

    onMounted(() => {
      particles = new Particles();
    });

    onBeforeUnmount(() => {
      particles?.dispose();
    });
  },
});
</script>
