import * as THREE from "three";

export default class Particles {
  camera: THREE.PerspectiveCamera;
  width: number;
  height: number;
  renderer: THREE.WebGLRenderer;
  scene: THREE.Scene;
  points: THREE.Points;
  mouseX: number;
  mouseY: number;
  clock: THREE.Clock;
  isMobile: boolean;
  animateId: number | null = null;

  constructor() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.clock = new THREE.Clock();
    this.mouseX = 1;
    this.mouseY = 1;
    this.isMobile = window.innerWidth <= 748;

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.1,
      100
    );
    this.camera.position.z = 5;

    this.points = this.createPoints();
    this.scene.add(this.points);

    const canvas = document.getElementById("particles") as HTMLCanvasElement;
    this.renderer = new THREE.WebGLRenderer({ canvas });
    this.renderer.setSize(this.width, this.height);

    this.animate();
  }

  createPoints(): THREE.Points {
    const geometry = new THREE.BufferGeometry();
    const particlesCount = 20000;
    const vertices = new Float32Array(particlesCount * 3);

    for (let i = 0; i < particlesCount * 3; i++) {
      vertices[i] = (Math.random() - 0.5) * 1000;
    }

    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

    const texture = new THREE.TextureLoader().load("/three/plus.png");

    const material = new THREE.PointsMaterial({
      color: 0xffffff,
      opacity: 0.5,
      size: 0.5,
      map: texture,
      transparent: true,
      blending: THREE.AdditiveBlending,
    });

    window.addEventListener("mousemove", this.onMouseMove);

    return new THREE.Points(geometry, material);
  }

  onMouseMove = (event: MouseEvent): void => {
    if (!this.isMobile) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    }
  };

  animate = (): void => {
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    const speed =
      this.clock.getElapsedTime() * (this.isMobile ? 0.05 : 0.00008);

    this.points.rotation.set(this.mouseY * speed, this.mouseX * speed, 1);

    this.animateId = requestAnimationFrame(this.animate);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.render(this.scene, this.camera);
    this.renderer.setClearColor(new THREE.Color(0x161616));
  };

  dispose() {
    cancelAnimationFrame(this.animateId!);
    window.removeEventListener("mousemove", this.onMouseMove);
    this.renderer.dispose();
  }
}
